.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*" !important;
  visibility: visible !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.ant-form-item-explain-error {
  color: red;
  max-width: 380px;
}
.ant-layout {
  background: #f4f4f4;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
  padding: 3px 6px !important;
}

.user-table .ant-table-body {
  min-height: calc(100vh - 450px);
}

.ant-input.right-aligned {
  text-align: right;
}
