.main-wrapper-div {
  padding: 20px;
  .filter-select {
    width: 205px;
  }
}

.active-switch {
  background-color: green;
}
.user-filter-form-wrapper {
  padding: 20px;
  Input {
    max-width: 160px;
    margin-bottom: 8px;
  }
  .filter-select {
    max-width: 140px;
    margin-bottom: 8px;
  }
}
