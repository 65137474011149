.layout-header {
  background: black;
  height: 125px;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.ant-menu-item-selected {
  background-color: black !important;
  border-bottom: 4px solid orange !important;
  border-radius: 2px !important;
}

.modified:hover {
  background: black !important;
}

.layout-menu-container {
  background: black;
  height: 60px;
  margin-top: -21px;
  margin-left: -31px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.layout-submenu-container {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.layout-submenu-container li:hover {
  background: orange !important;
}

.navbar-heading-master {
  color: white;
  margin-top: 0px;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.navbar-logo {
  display: flex;
  margin-left: -5px;
  flex-direction: row;
  img {
    max-width: 180px;
    max-height: 35px;
    margin: 14px 10px 0 0;
  }
}
.name-logo-container {
  display: flex;
  flex-direction: row;
  .name-container {
    color: white;
  }
  Button {
    margin-top: 14px;
    margin-right: -40px;
  }
}

.header-divider {
  color: white;
  margin: 0px 7px;
}

.ant-menu-sub,
.ant-menu-submenu,
.ant-menu-submenu:hover {
  background: black !important;
}
