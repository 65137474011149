.add-button-container {
  display: flex;
  justify-content: flex-end;
  .main-button {
    background-color: orange !important;
    color: white;
    height: 38px;
    border: none;
  }

  :hover {
    color: white !important;
  }
}
