@import "./styles/base/color.scss";
@import "./styles/LayoutHeader.scss";
// @import "~antd/dist/antd.css";
@import "./styles/LoginScreen.scss";
@import "./styles/addUserForm.scss";
@import "./styles/buttons/button.scss";
@import "./styles/components/addManufacturerForm.scss";
@import "./styles/screens/userScreen.scss";
@import "./styles/screens/emailScreen.scss";
@import "./styles/screens/accountScreen.scss";
@import "./styles/base/antdBase.scss";
@import "./styles/screens/errorScreen.scss";
@import "./styles/components/editManufacturerForm.scss";
@import "./styles/screens/manufacturerScreen.scss";
@import "./styles/screens/newPasswordScreen.scss";
@import "./styles/screens/systemConfigurationScreen.scss";
@import "./styles/components/addNewSyncConnectorForm.scss";
@import "./styles/components/AboutPage.scss";
@import "./styles/components/addDistributerForm.scss";
@import "./styles/components/AddOrganizationMappingForm.scss";
@import "./styles/components/editDistributorForm.scss";
@import "./styles/components/addDistributerForm.scss";
@import "./styles/components/PrimaryUserForm.scss";
@import "./styles/base/common.scss";
@import "./styles/screens/batchManagementScreen.scss";
@import "./styles/components/batchDataRectification.scss";

.App {
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 2px !important;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-top: -2px;
}
.sub-title {
  font-size: 18px;
  margin-top: 0px;
}

.tag-border-radius {
  border-radius: 30px;
}

.form-label {
  padding-bottom: 5px;
  margin: 0px;
  margin-left: 2px;
  font-size: 13px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.filter-form {
  padding: 20px;
  justify-content: space-between;
  flex-direction: row;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  .ant-input {
    max-width: 180px;
    margin-bottom: 8px;
  }
  .filter-select,
  .ant-picker,
  .ant-input-number {
    max-width: 160px;
    width: 160px;
    margin-bottom: 8px;
  }
  .filter-btn,
  .add-btn,
  .cancel-btn {
    margin-top: 18px;
  }
}
.datepicker {
  Input {
    margin-bottom: 0px;
  }
}
.batch-item-input {
  margin-right: 300px;
  Input {
    width: 320px;
    height: 48px;
  }
}

.ant-switch-handle::before {
  border-radius: 9px;
}
