.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  margin-bottom: -30px;
}

.sync-connector {
  height: 50px;
  width: 150px;
  margin-left: 10px;
}
