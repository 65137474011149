.login-form-container {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  height: 500px;
  width: 480px;
  margin-bottom: 70px;
}

.login-form-wrapper {
  background-color: #f4f4f4;
  min-width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-container input {
  min-width: 400px;
  min-height: 50px;
}

.main-login-heading {
  margin-top: 20px;
  font-size: 16px;

  .highlight {
    font-weight: bolder;
  }
}

.forgot-password {
  color: orange;
  padding-bottom: 10px;
  margin-top: 5px;
}

.logo-container {
  display: flex;
  align-items: center;
  img {
    max-width: 220px;
    max-height: 50px;
    padding-left: 10px;
  }
}

.login-heading {
  margin: 15px 70px 15px 0px;
}

.login-form-container .login-button {
  background: black !important;
  color: white;
  min-width: 150px;
  min-height: 50px;
}

.login-form-container .login-button:hover {
  background: black !important;
}

.ant-form-item-explain-error {
  color: red;
}
