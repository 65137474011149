.sub-wrapper-div {
  display: flex;
  margin-top: 60px;
  margin-left: -10px;
}

.profile-image-wrapper {
  display: flex;
}

.account-info-heading {
  display: flex;
}
.user-details {
  margin-left: 12px;
  h1 {
    font-size: 25px;
  }
  p {
    color: grey;
  }
}

.profile-image {
  min-width: 140px;
  max-height: 145px;
  background: mediumorchid;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    font-size: 80px;
    margin-top: 80px;
  }
}
