.cancel-link {
  color: black;
  margin-right: 300px;
  margin-top: -15px;
  cursor: pointer;
}

.newPassword-input {
  width: 350px;
}


.heading-container {
  margin-right: 20px;
}
