.distributorInput {
  margin-top: -1px;
}
.form-positioning {
  margin-left: -10px;
}
.distributor-form-subheading {
  font-size: 15px;
  font-weight: 550;
  margin-bottom: 20px;
}

.non-required {
  margin-top: 4px;
}
