.add-button-container-post {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  .main-button {
    background-color: green !important;
    color: white;
    height: 38px;
    min-width: 100px;
    border: none;
  }
  .main-button:hover {
    background-color: green !important;
    color: white !important;
  }
}

.next-button {
  background-color: green !important;
  color: white !important;
  border: none;
  min-width: 140px;
  min-height: 50px;
  font-size: 20px;
}
.next-button:hover {
  background: green !important;
  color: white !important;
}

.close-button {
  background-color: #3a3b3c !important;
  color: white !important;
  border: none;
  min-width: 140px;
  min-height: 50px;
  font-size: 20px;
}
.close-button:hover {
  background: 3a3b3c !important;
  color: white !important;
}

.save-button {
  background-color: orange !important;
  color: white !important;
  border: none;
  min-width: 140px;
  min-height: 50px;
  font-size: 20px;
}
.save-button:hover {
  background: orange !important;
  color: white !important;
}

.warning-text {
  h3 {
    color: red;
  }
}

//edit batch screen tag style
.edit-batch-tag {
  margin: 0px 0 0 10px;
}

//total count style
.total-count {
  font-size: 14px;
  margin: -47px 0 0 10px;
}

//edit batch item

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
  width: 200px;
  height: 35px;
  margin: 10px 0 10px 0;
}

.disbaled-label {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
  cursor: not-allowed;
  width: 200px;
  height: 35px;
  margin: 10px 0 10px 0;
}

.header {
  color: #bbbcbb;
}

.image-details {
  display: flex;
  justify-content: space-between;
  color: orange;
}
.warning-text {
  color: red;
}
.deleteOutlined {
  color: red;
  margin: 2px 30px 0 0;
}

.voided > .ant-table-cell {
  background: #d5d5d5 !important;
}

.ant-carousel .slick-dots-bottom {
  display: none !important;
}

.btn-orange {
  background: orange !important;
  border: 0px;
  :hover {
    background: orange !important;
    border: 0px;
  }
  [disabled] {
    background: #d5d5d5 !important;
  }
}

.manufacturer-form {
  display: flex;
  gap: 5px;
  align-items: center;
}
