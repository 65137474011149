.add-btn {
  background-color: orange !important;
  color: white !important;
  border: none !important;
}

.cancel-btn {
  background-color: #3a3b3c !important;
  border: none;
  color: white !important;
}

.cancel-btn:hover {
  background-color: #3a3b3c !important;
}

.ant-btn {
  border-radius: 2px;
}
