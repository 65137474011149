.forgot-password-form-wrapper {
  display: flex;
  justify-content: center;
  // min-height: 70vh;
  height: 80vh;
  background-color: #f4f4f4;
}
.forgot-password-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 450px;
  height: 420px;
  margin-right: 20px;
  padding: 30px;
  font-size: 16px;
}

.continue-button {
  background-color: black !important;
  color: white !important;
  font-size: 13px;
  width: 150px;
  height: 50px;
}

.logo-container {
  margin-top: 15px;
}
