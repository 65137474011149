.description {
  display: flex;
  .desc-paragraph1 {
    margin-left: 150px;
  }
  .desc-paragraph2 {
    margin-left: 140px;
  }
  .desc-paragraph3 {
    margin-left: 112px;
  }
}

.about-page-divider {
  margin-top: -10px;
}
.about-heading {
  font-size: 25px;
  margin-bottom: 20px;
}
