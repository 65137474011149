.add-new-link {
  background: orange !important;
  color: white !important;
  border: none;
  cursor: pointer;
  margin-top: -6px;
}
.refresh-button {
  background: skyblue !important;
  color: white !important;
  border: none;
  cursor: pointer;
  margin-top: -6px;
}
