.first-carousel-form-container {
  margin-top: 80px;
}

.next-btn {
  margin-top: 390px;
}

.details-wrapper {
  display: flex;
  justify-content: space-between;
}

.collapse-style {
  min-width: 95vw;
  margin-top: 20px;
}
.carousel2-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
