.form-sub-heading {
  .arrow-img {
    margin: 0px 30px 0 0;
    cursor: pointer;
  }
  display: flex;
}
.manufacturer-form-button-container {
  display: flex;
  margin-left: -11px;
  .add-user-button {
    height: 50px;
    width: 150px;
    margin-left: 10px;
  }
  .cancel-btn {
    height: 50px;
    width: 150px;
    margin-left: 10px;
  }
}

.capitalise {
  text-transform: uppercase;
  margin-top: 4px;
}
