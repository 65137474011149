.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
  border-radius: 2px 2px 0 0 !important;
  padding: 0px 16px;
  font-size: 14px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  h1 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #f0f1f5;
  border-top: 3px orange solid;
  margin-bottom: -2px;
  color: black;
}

.add-connector-btn {
  background-color: orange !important;
  color: white !important;
  height: 50px;
  margin: -18px;
  border: none;
}

.add-connector-button-container {
  display: flex;
  justify-content: flex-end;
}
