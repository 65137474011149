.save-manufacturer-button {
  background-color: orange !important;
  height: 50px;
  width: 150px;
  margin-left: 10px;
  border: none;
}

.id-input {
  margin-top: 5px;
}

.select-validation {
  color: orange;
  margin-top: -14px;
}
