.add-user-button {
  background: #008000 !important;
  min-height: 50px;
  min-width: 150px;
  border: none;
  color: white;
}

.add-user-button:hover {
  background: green !important;
}
.edit-user-button:hover {
  background: orange !important;
}

// for changing switch color
.ant-switch-checked {
  background-color: green !important;
}

.edit-user-button {
  background: #ffa500 !important;
  min-height: 50px;
  min-width: 150px;
  border: none;
  color: white;
}
