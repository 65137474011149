.error-screen-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f4f4f4;

  h1 {
    font-size: 200px;
  }
  p {
    font-size: 25px;
    margin-bottom: 100px;
    margin-top: -170px;
  }
}
